import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleLeft, faAngleRight, faBars, faHouse, faCompassDrafting, faWifi, faEye, faKitchenSet, faWaterLadder, faDoorOpen, faTree, faHouseFloodWater } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'

library.add(faAngleLeft, faAngleRight, faBars, faFacebook, faInstagram, faTiktok, faHouse, faCompassDrafting, faWifi, faEye, faKitchenSet, faWaterLadder, faDoorOpen, faTree, faHouseFloodWater);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);

app.use(router);

app.mount('#app');
