<template>
  <div class="home">
    <div class="section main-slider ">
      <div class="image-slider"></div>
      <h1 class="title">Discover Luxury Villas Proistakis</h1>
      <div class="description">Immerse yourself in the ultimate vacation experience.</div>
      <a href="https://proistakisvillas.reserve-online.net" class="book-button">Book Now</a>
    </div>
    <div class="section about">
      <div class="title">Proistakis Villas</div>
      <div class="description">Escape to our carefully chosen destinations, where relaxation reaches new heights, and every moment is designed to exceed the expectations of even the most discerning traveler. Whether it's lounging on sun-drenched beaches or immersing yourself in the tranquility of lush landscapes, our handpicked havens promise an unparalleled retreat. With luxurious accommodations and tailored experiences awaiting, cherish precious time with loved ones amidst breathtaking surroundings. Embrace the perfect blend of leisure and luxury as you create unforgettable memories in these idyllic sanctuaries of serenity.</div>
      <div class="villas-preview">
        <div class="villa"  v-for="(villa, index) in villas" :key="index" @click="redirect(`villa/${villa.name}`)">
          <img alt="preview" :src="`/villas_photos/${villa.name}/image-${villa.preview_image}.webp`">
          <div class="vtitle">{{ villa.label }}</div>
          <div class="vdescription">{{ villa.info.bedrooms }} Bedroom(s) | {{ villa.info.bathrooms }} Bathroom(s){{ villa.info.beachfront ? ' | Beachfront' : ''}}</div>
        </div>
      </div>
    </div>
    <div class="section waterfront_villas">
      <div class="images">
        <img alt="back image" class="back" src="/villas_photos/studio_summer_vibes/image-3.webp">
        <img alt="front image" class="front" src="/general-photo-2.webp">
      </div>
      <div class="info">
        <div class="title">Waterfront Villas</div>
        <div class="description">A waterfront villa is the ultimate luxury holiday home, particularly when the house features a secluded beach.</div>
        <a href="/our-villas" class="button">READ MORE</a>
      </div>
    </div>
    <div class="section explore_destinations">
      <div class="title">Top Destinations You Must Visit</div>
      <div class="description">Explore new breathtaking destinations in our place</div>
      <a class="button" href="/discover">View More</a>
    </div>
    <div class="section explore_villas">
      <div class="info">
        <div class="title">Explore Our Villas</div>
        <div class="description">Our mission is to provide high-quality accommodation for all those that wish to combine fun and enjoyable ambiance with a central location on one of the most extraordinary places in the world!</div>
        <a href="/our-villas" class="button">READ MORE</a>
      </div>
      <div class="images">
        <img alt="back image" class="back" src="/villas_photos/villa_proistakis/image-1.webp">
        <img alt="front image" class="front" src="/villas_photos/villa_proistakis/image-3.webp">
      </div>
    </div>
    <div class="section questions">
      <div class="title">Have a Question?</div>
      <div class="description">Talk to the Host</div>
      <a class="button" href="/contact">Get In Touch</a>
    </div>
<!--    <div class="section reviews-slider">-->
<!--      <Reviews :reviews />-->
<!--    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'HomeView',
  data() {
    return {
      villas: [],
    }
  },
  methods: {
    redirect(name: string) {
      window.location.href = `/${name}`;
    }
  },
  mounted() {
    // @ts-ignore
    this.villas = this.$root.getVillas()
  }
});
</script>

<style lang="scss">
.home {
  .section {
    margin-bottom: 50px;
  }

  .main-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .image-slider {
      height: 60rem;
    }

    .image-slider:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: blur(0.5px);
      z-index: -1;
      background-image: url(/public/villas_photos/best_1.webp);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
    }

    .title {
      position: absolute;
      color: white;
      margin-bottom: 15rem;
      font-size: 5rem;
      font-family: sans-serif;
      font-weight: 500;
    }

    .description {
      position: absolute;
      color: white;
      margin-bottom: 3rem;
      font-size: 20px;
    }

    .book-button {
      position: absolute;
      background-color: #96897b;
      width: 7%;
      padding: 20px 10px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 5rem;
      text-decoration: none;
    }

    .image_slider {
      height: 55rem;
    }
  }

  .about {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 30px;
    }

    .description {
      margin-top: 25px;
      width: 60%;
    }

    .villas-preview {
      margin-top: 60px;
      display: flex;
      width: 95%;
      justify-content: space-evenly;
      flex-wrap: wrap;

      .villa {
        margin-bottom: 55px;
        background-color: white;
        box-shadow: 0 0 18px -4px black;
        border-radius: 7px;
        cursor: pointer;
        transition: transform 0.3s ease;

        .vtitle {
          margin: 1rem 0 0 1rem;
          text-align: start;
          font-weight: bold;
        }

        .vdescription {
          margin: 1rem 0 2rem 1rem;
          text-align: start;
          font-size: 12px;
        }

        img {
          border-start-start-radius: 7px;
          height: 10rem;
          object-fit: cover;
          width: 20rem;
          border-top-right-radius: 7px;
        }
      }

      .villa:hover {
        background-color: #f3f3f3 !important;
        transform: scale(1.1) !important;
      }
    }
  }

  .waterfront_villas {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    background-color: #f8f8f8 !important;
    justify-content: space-evenly;

    .images {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .back {
        width: 31rem;
        max-width: 500px;
        height: 42rem;
        object-fit: cover;
        border-radius: 3px;
      }

      .front {
        width: 22rem;
        height: 460px;
        object-fit: cover;
        object-position: 55%;
        position: absolute;
        border-radius: 3px;
        margin-left: 18rem;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-left: 5rem;
      width: 25%;

      .title {
        font-size: 45px;
        font-weight: 400;
      }

      .description {
        text-align: left;
        font-size: 20px;
      }

      .button {
        background-color: #96897b;
        padding: 20px 10px;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 30px;
        text-decoration: none;
      }
    }
  }

  .explore_destinations {
    margin-top: 10rem;
    background-image: url(/public/chryssi.webp);
    height: 40rem;
    background-repeat: no-repeat;
    background-position: 0 99%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
      color: white;
      font-family: none;
      font-size: 4rem;
    }

    .description {
      font-size: 1rem;
      color: white;
    }

    .button {
      background-color: #96897b;
      padding: 20px 10px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 30px;
      text-decoration: none;
    }
  }

  .explore_villas {
    margin-top: 10rem;
    width: 100%;
    display: flex;
    background-color: #f8f8f8 !important;
    justify-content: space-evenly;

    .images {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .back {
        width: 31rem;
        max-width: 500px;
        height: 42rem;
        object-fit: cover;
        border-radius: 3px;
      }

      .front {
        width: 22rem;
        height: 460px;
        object-fit: cover;
        object-position: 70%;
        position: absolute;
        border-radius: 3px;
        margin-left: -8rem;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 5rem;
      width: 25%;

      .title {
        font-size: 45px;
        font-weight: 400;
      }

      .description {
        text-align: left;
        font-size: 20px;
      }

      .button {
        background-color: #96897b;
        padding: 20px 10px;
        color: white;
        text-transform: uppercase;
        font-weight: 600;
        border-radius: 10px;
        cursor: pointer;
        margin-top: 30px;
        text-decoration: none;
      }
    }
  }

  .questions {
    padding: 65px 0;
    background-color: #e9e9e9;

    .title {
      font-size: 35px;
      font-weight: 500;
    }

    .description {
      font-size: 17px;
      margin-top: 10px;
    }

    .button {
      margin-top: 3.5rem;
      background-color: #96897b;
      padding: 20px 10px;
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      border-radius: 10px;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .home {
    .main-slider {
      .title {
        font-size: 3rem !important;
      }

      .description {
        font-size: 15px !important;
        width: 80% !important;
      }

      .book-button {
        width: 25% !important;
      }
    }

    .about .description {
      width: 95% !important;
    }

    .waterfront_villas {
      flex-direction: column;
      align-items: center;

      .images {
        .back {
          display: none;
        }

        .front {
          position: unset !important;
          margin: 0 !important;
        }
      }

      .info {
        margin: 2rem 0 0 !important;
        width: 80% !important;
        align-items: center !important;
      }
    }

    .explore_villas {
      flex-direction: column-reverse;
      align-items: center;

      .images {
        .back {
          display: none;
        }

        .front {
          position: unset !important;
          margin: 0 !important;
        }
      }

      .info {
        margin: 2rem 0 0 !important;
        width: 80% !important;
        align-items: center !important;
      }
    }
  }
}
</style>