<template>
  <nav id="navbar">
    <div class="logo">
      <img alt="logo" src="../public/logo.png" />
    </div>
    <div class="nav-links">
      <div class="desk">
        <a href="/">Home</a>
        <a href="/our-villas">Our Villas</a>
        <a href="/discover">Discover</a>
        <a href="/contact">Contact Us</a>
      </div>
      <div class="mobile">
        <font-awesome-icon @click="openNav" icon="bars"/>
      </div>
    </div>
  </nav>
  <div class="mobileNav" v-if="navOpen">
    <div class="mob-nav-links">
      <a @click="openNav" href="/">Home</a>
      <a @click="openNav" href="/our-villas">Our Villas</a>
      <a @click="openNav" href="/discover">Discover</a>
      <a @click="openNav" href="/contact">Contact Us</a>
    </div>
  </div>
  <router-view v-if="!navOpen" />
  <div class="footer">
    <div class="logo">
      <img alt="logo" src="/logo.png">
    </div>
    <div class="info">
      <div class="contact-details">
        <div class="title">Contact Us</div>
        <div class="loc">{{ info.location }}</div>
        <div class="tel">Call: <a :href="'tel:' + info.telephone">{{ info.telephone }}</a></div>
        <div class="email">Email: <a :href="'mailto:' + info.email">{{ info.email }}</a></div>
      </div>
    </div>
    <div class="copyrights">Made from George Rizopoulos. © Copyrights {{ new Date().getFullYear() }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  data() {
    return {
      info: {
        location: 'Villa Proistakis, Ierapetra, Lasithi, Crete, Greece',
        email: 'proist@otenet.gr',
        telephone: '+30 694 555 3178',
        social: {
          facebook: 'https://www.facebook.com/people/Villa-proistakis/100063573460991/',
          instagram: 'https://www.instagram.com/villa_proistakis',
          tiktok: 'https://www.tiktok.com/@villaproistakis'
        }
      },
      villas: [
        {
          name: 'mystique_luxury_suite',
          label: 'Mystique Luxury Suite',
          info: {
            bedrooms: 2,
            bathrooms: 2,
            beachfront: true
          },
          preview_image: 1,
          photos_num: 33,
          description: 'Nestled in the tranquil village of Achlia, Mystique Luxury Suite offers an exclusive retreat for those seeking refined elegance and ultimate relaxation. This chic villa features a rooftop pool, free WiFi, and private parking, providing guests with modern comforts amidst a picturesque setting. With air-conditioned accommodation, a balcony, and access to a hot tub, hammam, and steam room, every detail is crafted for indulgence. The villa\'s two bedrooms, sea-view terrace, and well-appointed kitchen ensure a seamless blend of luxury and convenience. Just a short walk from Achlia Beach and a quick drive from Sitia Public Airport, Mystique Luxury Suite promises a memorable escape in the heart of Crete\'s captivating landscape.',
          mini_description: 'In Achlia, Mystique Luxury Suite offers a cozy retreat with a rooftop pool and free WiFi. This air-conditioned villa features a balcony, hot tub, and hammam. With two bedrooms, sea views, and modern amenities, it\'s perfect for a relaxing getaway. Just a short walk from Achlia Beach and a quick drive from Sitia Public Airport, it\'s an ideal escape for those seeking tranquility and convenience.',
          features: {
            whole_villa: true,
            size: 130,
            wifi: true,
            view: true,
            kitchen_equipment: true,
            balcony: true,
            garden: true,
            hydromassage: true,
            pool: true
          }
        },
        {
          name: 'villa_proistakis',
          label: 'Villa Proistakis',
          info: {
            bedrooms: '4-6',
            bathrooms: 1,
            beachfront: true
          },
          preview_image: 2,
          photos_num: 20,
          description: 'Nestled near Achlia Beach and 47 kilometers from Lake Voulismeni, Villa Proistakis offers air-conditioned rooms with private bathrooms and scenic views. Guests enjoy free parking and a paid airport shuttle. Apartments feature sea-view patios, fully equipped kitchens, and free WiFi. The complex includes barbecue facilities, and activities like cycling and fishing are nearby. Sitia Public Airport is 45 kilometers away.',
          mini_description: 'Villa Proistakis offers air-conditioned rooms near Achlia Beach and Lake Voulismeni, with free parking and a paid airport shuttle. Apartments include sea-view patios, kitchens, and free WiFi. On-site amenities feature barbecue facilities, with nearby activities like cycling and fishing. Sitia Public Airport is 45 kilometers away.',
          features: {
            whole_villa: true,
            size: '40-80',
            wifi: true,
            view: true,
            kitchen_equipment: true,
            balcony: true,
            garden: true,
            hydromassage: false,
            pool: true
          }
        },
        {
          name: 'bay_view_residence',
          label: 'Bay View Residence',
          info: {
            bedrooms: 2,
            bathrooms: 1,
            beachfront: true
          },
          preview_image: 1,
          photos_num: 21,
          description: 'Perched along the scenic coastline, Bay view residence offers a serene beachfront escape just 1.5 km from Beach Maheridi. With panoramic sea views, a private balcony, and terrace, guests can immerse themselves in seaside tranquility. The two-bedroom holiday home features modern amenities including free WiFi, air conditioning, and a fully equipped kitchen. Conveniently located 2.1 km from Achlia Beach and 49 km from Lake Voulismeni, this charming retreat provides easy access to nearby attractions. Sitia Public Airport is just 41 km away, ensuring a seamless journey to this coastal haven.',
          mini_description: 'Bay view residence, about 1.5 km from Beach Maheridi, offers sea views, a garden, and a balcony. This beachfront property includes a terrace and free parking. With two bedrooms, a kitchen, and WiFi, it\'s ideal for a seaside getaway. It\'s also near Achlia Beach (2.1 km), Lake Voulismeni (49 km), and Sitia Public Airport (41 km).',
          features: {
            whole_villa: false,
            size: 60,
            wifi: true,
            view: true,
            kitchen_equipment: true,
            balcony: true,
            garden: true,
            hydromassage: false,
            pool: false
          }
        },
        {
          name: 'chelidonofolia',
          label: 'Chelidonofolia',
          info: {
            bedrooms: '1-2',
            bathrooms: 1,
            frontbeach: false
          },
          preview_image: 1,
          photos_num: 17,
          description: 'Nestled amidst stunning mountain vistas, Chelidonofolia offers a serene retreat just 42 kilometers from Lake Voulismeni. This inviting apartment welcomes guests with a private entrance for added convenience, along with air-conditioned rooms, complimentary private parking, and free WiFi. Each unit boasts a charming terrace with captivating sea views, complemented by a flat-screen TV, dining area, and well-equipped kitchen featuring an oven, microwave, fridge, coffee machine, and kettle. The apartment complex ensures a comfortable stay with provided bed linen and towels. Guests can unwind in the tranquil garden surroundings, promising a peaceful getaway amidst the beauty of Crete\'s landscape.',
          mini_description: 'Chelidonofolia offers a tranquil retreat with mountain views, about 42 kilometers from Lake Voulismeni. This charming apartment features air-conditioned rooms, free private parking, and WiFi. Each unit includes a terrace with sea views, a flat-screen TV, dining area, and well-equipped kitchen. Guests can enjoy the peaceful garden surroundings for a relaxing getaway in Crete.',
          features: {
            whole_villa: false,
            size: 60,
            wifi: true,
            view: true,
            kitchen_equipment: true,
            balcony: true,
            garden: true,
            hydromassage: false,
            pool: false
          }
        },
        {
          name: 'studio_summer_vibes',
          label: 'Studio Summer Vibes',
          info: {
            bedrooms: 1,
            bathrooms: 1,
            beachfront: true
          },
          preview_image: 1,
          photos_num: 12,
          description: 'Located in Achlia, Summer Vibes offers beachfront accommodation steps away from Achlia Beach, with amenities like a garden and barbecue facilities. Just 2.3 km from Agia Fotia Beach, it provides bicycle parking. Each air-conditioned unit offers sea views, picnic areas, and free WiFi. The apartments feature modern amenities like a flat-screen TV and a fully equipped kitchen. Guests can enjoy outdoor dining on the patio and a bicycle rental service is available. Situated near Lake Voulismeni and Panagia Kera Church, with Sitia Public Airport 46 km away, Summer Vibes is ideal for a seaside retreat in Crete.',
          mini_description: 'Summer Vibes, located in Achlia, offers beachfront accommodation steps away from Achlia Beach. Amenities include a garden, barbecue facilities, and bicycle parking. The air-conditioned units feature sea views, picnic areas, and free WiFi. Each unit includes a terrace, private bathroom, and outdoor dining area. Kitchen facilities allow for self-catering, and a bicycle rental service is available. Nearby attractions include Agia Fotia Beach and Panagia Kera Church, with Sitia Public Airport just 46 km away.',
          features: {
            whole_villa: false,
            size: 40,
            wifi: true,
            view: true,
            kitchen_equipment: true,
            balcony: true,
            garden: true,
            hydromassage: false,
            pool: false
          }
        },
        {
          name: 'luxury_villa_asana',
          label: 'Luxury Villa Asana',
          info: {
            bedrooms: 3,
            bathrooms: 3,
            beachfront: true
          },
          preview_image: 1,
          photos_num: 9,
          description: 'Luxury Villa Asana in Koutsouras offers a serene escape with modern amenities, including free WiFi, air conditioning, an outdoor pool, and a lush garden. Located just 400 meters from Koutsouras Beach and 1.6 km from Macherida Beach, this villa is perfect for a relaxing vacation. It features 3 bedrooms, 3 bathrooms, a spacious living area, and a fully-equipped kitchen. Guests can unwind on the terrace or take advantage of the free private parking. The villa is conveniently situated 38 km from Sitia Public Airport, making it easily accessible for travelers.',
          mini_description: 'Luxury Villa Asana is a stylish villa in Koutsouras with free WiFi, air conditioning, an outdoor pool, and a garden. Just 400m from Koutsouras Beach, this 3-bedroom villa offers a comfortable stay with a terrace and private parking. The Sitia Public Airport is 38 km away.',
          features: {
            whole_villa: true,
            size: 140,
            wifi: true,
            view: true,
            kitchen_equipment: true,
            balcony: true,
            garden: false,
            hydromassage: false,
            pool: false
          }
        },
      ],
      navOpen: false
    }
  },
  methods: {
    getInfo() {
      return this.info;
    },
    getVillas() {
      return this.villas;
    },
    getVilla(id: string) {
      return this.villas.find(v => v.name === id);
    },
    getImagePath(villa: string, image: number) {
      return `/villas_photos/${villa}/image-${image}.webp`
    },
    openNav() {
      this.navOpen = !this.navOpen;
    },
    redirect(url: string) {
      window.location.href = url;
    }
  },
  mounted() {
    window.onscroll = function() {
      let navbar = document.getElementById('navbar');
      if(!navbar) return;

      if (window.pageYOffset > 50) {
        navbar.classList.add('scrolled');
      } else {
        navbar.classList.remove('scrolled');
      }
    };
  }
});
</script>

<style>
body {
  padding: 0;
  margin: 0;
  background-color: #f8f8f8;
}

#app {
  font-family: 'Comfortaa', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 5px 25px 5px 0;
  z-index: 9;
  transition: background-color 0.5s ease;

  .logo img {
    width: 12rem;
    height: 4.7rem;
  }

  .nav-links {
    margin-right: 1rem;

    .desk {
      display: flex;
      width: 30rem;
      justify-content: space-between;
    }

    .mobile {
      display: none;
      font-size: 1.3rem;
      color: white !important;
    }

    a {
      text-decoration: none;
      color: black;
    }

    a.router-link-exact-active {
      color: white;
    }
  }
}

.scrolled {
  background-color: rgb(242 242 242 / 48%);
  backdrop-filter: blur(10px);
}

.mobileNav {
  display: flex !important;

  .mob-nav-links {
    width: 100%;
    z-index: 6;
    display: flex;
    flex-direction: column;

    a {
      color: white;
      width: 50%;
      text-align: start;
      font-size: 5vw;
      margin: 0 0 1.5rem 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
      text-decoration: none !important;
    }

    a:first-child {
      margin-top: 7rem;
    }
  }
}

.mobileNav:before {
  content: "";
  position: fixed;
  background-color: #424549;
  height: 100%;
  width: 100%;
  z-index: 5;
}


.footer {
  display: flex;
  background-color: #dadada;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
  padding: 40px 0 10px;

  .logo {
    width: 50%;

    img {
      width: 16rem;
    }
  }

  .info {
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    .contact-details .title {
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: 700;
    }
  }

  .copyrights {
    margin-top: 10px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 1300px) {
  .desk {
    display: none !important;
  }

  .nav-links {
    justify-content: flex-end !important;

    .mobile {
      display: block !important;
    }
  }

  .logo img {
    width: 10rem !important;
    height: 3.8rem !important;
  }

  .contact-details {
    .title {
      font-size: 18px !important;
    }

    .loc, .tel, .email {
      font-size: 14px;
    }
  }
}
</style>